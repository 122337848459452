import { Grid } from '@hexa-ui/components';
import { List2, Rules } from '@hexa-ui/icons';
import {
  setAppHeaderConfig,
  useAppHeader,
  useSidebar,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { useAdapters } from 'contexts/AdaptersContext';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { BASE_PATH, WORKFLOW_PATH } from 'src/routes';
import { setCountry } from 'store/slices/reports.slice';
import { useEnvContext } from 'utils/EnvProvider/EnvProvider';
import { isFeatureEnabled } from 'utils/featureToggles';

export interface AppbarConfigProps {
  children: React.ReactNode;
}

/* istanbul ignore next */
const RulesIcon = () => <Rules />;
const List2Icon = () => <List2 />;

export const AppbarConfig = ({ children }: AppbarConfigProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const { REACT_APP_HOME_PATH } = useEnvContext();
  const [isUpdated, setIsUpdated] = useState(false);
  const { data, isLoading } = useUserMetadata();
  const [{ selectedCountry }] = useAppHeader();
  const dispatch = useDispatch();
  const [countriesOptions, setCountriesOptions] = useState<string[]>([]);
  const { pathSegments } = useAdapters();

  const getBreadcrumbConfig = useCallback(() => {
    const breadcrumbItems = pathSegments.map((segment, index) => {
      if (segment === 'bees-gateway-web') {
        return {
          label: 'BEES Pay Portal',
          path: `/${pathSegments.slice(0, index + 1).join('/')}`,
          isCurrentPage: index === pathSegments.length - 1,
        };
      }

      return {
        label: segment.charAt(0).toUpperCase() + segment.slice(1),
        path: `/${pathSegments.slice(0, index + 1).join('/')}`,
        isCurrentPage: index === pathSegments.length - 1,
      };
    });

    return {
      homePath: `/${REACT_APP_HOME_PATH}`,
      items: [...breadcrumbItems],
    };
  }, [pathSegments, REACT_APP_HOME_PATH]);

  const getPageTitle = useCallback(() => {
    const currentSegment = pathSegments[pathSegments.length - 1];
    if (currentSegment === 'bees-gateway-web' || !currentSegment) {
      return undefined;
    }
    return formatMessage({ id: `PageTitles.${currentSegment.toUpperCase()}` });
  }, [pathSegments, formatMessage]);

  useEffect(() => {
    const breadcrumbConfig = getBreadcrumbConfig();
    const pageTitle = getPageTitle();
    setAppHeaderConfig({
      breadcrumbConfig,
      pageTitle,
    });
  }, [pathSegments, getBreadcrumbConfig, getPageTitle]);

  useEffect(() => {
    if (!isLoading && data?.supportedCountries) {
      const sortedCountries = [...data.supportedCountries].sort((a, b) => a.localeCompare(b));
      setCountriesOptions(sortedCountries);
    }
  }, [isLoading, data, isUpdated, selectedCountry]);

  // TODO: REMOVER ESTE DISPATCH
  useEffect(() => {
    dispatch(setCountry(selectedCountry));
  }, [selectedCountry, dispatch]);

  useEffect(() => {
    if (!isLoading && data && !isUpdated && countriesOptions.length > 0) {
      setAppHeaderConfig({
        countrySelect: true,
        defaultCountry: selectedCountry,
        countryOptions: countriesOptions,
      });
      setIsUpdated(true);
    }
  }, [data, isUpdated, isLoading, countriesOptions, selectedCountry]);

  const items = [
    {
      id: 'payments',
      title: formatMessage({ id: 'PageTitles.PAYMENTS' }),
      icon: RulesIcon,
      path: `${BASE_PATH}/payments`,
    },
  ];

  if (isFeatureEnabled('workflow')) {
    items.push({
      id: 'workflow',
      title: formatMessage({ id: 'PageTitles.WORKFLOW' }),
      icon: List2Icon,
      path: WORKFLOW_PATH,
    });
  }

  useSidebar({
    items,
    utils: [],
  });

  return (
    <Grid.Container
      style={{
        rowGap: '1rem',
      }}
      type="fluid"
      sidebar
    >
      {children}
    </Grid.Container>
  );
};
